/**
 * @file
 * Custom scripts for theme.
 */
(function ($) {
  //runs once when dom is ready
	$(function(){
		
		$('a[href$=".pdf"]').prop('target', '_blank');
		
		
    $overflow_hidden_div = $('<div />').addClass('overflow-hidden-div').click(function(){
      $('#navbar-collapse').collapse('hide');
    });
    $('body').append($overflow_hidden_div);
    
    
    $('.navbar-toggle').click(function(){
      $('#navbar-collapse').addClass('clicked');
    });
		
		
		$(document).on('click', '[data-toggle="lightbox"]', function(event) {
			event.preventDefault();
			if($(this).parents('.photos-and-downloads')){
				$(this).data('title',$(this).parents('.photos-and-downloads').prev().find('p').text());
			}
			$(this).ekkoLightbox({
				alwaysShowClose: true
			});
		});

		
    
/*
    $('.sidebar-panel-inside').affix({ //sticky header at bottom of bg
        offset: {
          //top: function(){return $("#bg").outerHeight();}
          bottom: function(){ return $('#footer').outerHeight()+40; }
        }
    });



$('.sidebar-panel-inside').affix({
  offset: {
    bottom: function(){ return $('#footer').outerHeight()+40; }
  }
});


$('.sidebar-panel-inside').on('affix.bs.affix', function () {
    console.log('affix.bs.affix');
}).on('affixed.bs.affix', function () {
    console.log('affixed.bs.affix');
});
  */  
    
    if($('.pane-menu-tree').length){
      $('.pane-menu-tree .pane-title').append('<a href="#" data-toggle="collapse" data-target="#navbar-collapse-secondary" class="arrow visible-xs visible-ms"><span class="glyphicon glyphicon-chevron-right"></span><span class="sr-only">Toggle Dropdown</span></a>');
      $('.pane-menu-tree .pane-content').attr('id','navbar-collapse-secondary').addClass('collapse');
    }
    $(document).on('show.bs.collapse','#navbar-collapse-secondary',function(e){
      $('span.glyphicon-chevron-right').addClass('rotate');
    }).on('hide.bs.collapse','#navbar-collapse-secondary',function(e){
      $('span.glyphicon-chevron-right').removeClass('rotate');
    });
    //$('#navbar-collapse-secondary').collapse('hide');
    
   
/*	 
    $(document).on('show.bs.collapse','.views-field-views-conditional-1',function(e){
			$(this).find('.btn span:first-child').text('Close Details').next().addClass('rotate');
    }).on('hide.bs.collapse','.views-field-views-conditional-1',function(e){
			$(this).find('.btn span:first-child').text('View Details').next().removeClass('rotate');
    });	 
	*/  
    
    $(document).on('show.bs.dropdown',function(e){
      $(e.relatedTarget).find('.glyphicon-chevron-right').toggleClass('rotate');
    }).on('hide.bs.dropdown',function(e){
      $(e.relatedTarget).find('.glyphicon-chevron-right').toggleClass('rotate');
    }).on('show.bs.collapse','#navbar-collapse',function(e){
      $('.navbar-header .navbar-toggle').toggleClass('is-active');
      $('body').addClass('overflow-hidden'); 
    }).on('hide.bs.collapse','#navbar-collapse',function(e){
      $('.navbar-header .navbar-toggle').toggleClass('is-active');      
      $('body').removeClass('overflow-hidden');
      $('#navbar-collapse').css('maxHeight','none');
    }).on('hidden.bs.collapse','#navbar-collapse',function(e){  
      $('#navbar-collapse').removeClass('clicked');
    }).on('shown.bs.collapse','#navbar-collapse',function(e){  
      if($('#header').height() > $(window).height() ){
        $nav_maxHeight = $(window).height()-$('#header .navbar-header').height();
        if($('body').hasClass('navbar-vertical')){
          $nav_maxHeight = $nav_maxHeight- $('#navbar-bar').outerHeight();
        }
        $('#navbar-collapse').css('maxHeight',$nav_maxHeight+'px');       
      }
    }).on('click.bs.dropdown.data-api','li.split ul.dropdown-menu',function(e){
      console.log('stop prop');
      e.stopPropagation()
    });
    
    $('#modalSearch').on('shown.bs.modal', function () {
      $(this).find('.modal-body input[type="text"]:first-child').focus();
    }).on('hide.bs.modal',function(){
      //$(this).find('.modal-body input[type="text"]:first-child').val("");
    });
    
    /*
    $(window).smartresize(function () {
        if ($.isXs()) {
            console.log('Is xs breakpoint :)');
        } else if ($.isSm()) {
            console.log('Is sm breakpoint :)');
        } else if ($.isMd()) {
            console.log('Is md :)');
        } else if ($.isLg()) {
            console.log('Is lg breakpoint :)');
        }
    },'bootstrap-breakpoint').resize();
    */
    //breakpoints = $('body').sassToJs({pseudoEl: '::before'});

    breakpoints = $('#breakpoints_sass').sassToJs({pseudoEl: '::before',cssProperty: 'content'});
   
    
    console.log("screen and (max-width:"+breakpoints.sm+")");
    console.log(breakpoints.grid_float_breakpoint);
  

var resize_nav_overflow = function(){
  console.log('running resize nav overflow');

  $('body').once('resize_nav_overflow',function(){
    console.log('binding smart resize');
    $(window).smartresize(function(){
      console.log('running smart resize');
      console.log('header height :'+$('#header').height());
      console.log('window height :'+$(window).height());
      console.log($('#header').height() > $(window).height());
      $('#navbar-collapse').css('maxHeight','none');
      if($('#header').height() > $(window).height() ){
        $('#navbar-collapse').css('maxHeight',$(window).height()-$('#header .navbar-header').height()+'px');       
      }else{
        $('#navbar-collapse').css('maxHeight','none');
      }
    },'nav.resize').resize();
  });
  
  
};
resize_nav_overflow();
    
  
    
    var nav_collapse_functions = {
      match: function(){
        console.log('running nav collapse match');
        $('li.split').hover(function(){
          console.log('hover');
          $(this).find('[data-toggle=dropdown]').dropdown('toggle');
        },function(){
          console.log('unhover');
          $(this).find('[data-toggle=dropdown]').dropdown('toggle')
        });
        $(window).smartresize(function(){ console.log('resize bs dropdowns'); bootstrap_OffsetDropdown.load(); },'bs.dropdown').resize();
        $(window).off('resize.nav.resize');
        $('body').removeClass('overflow-hidden').removeClass('resize_nav_overflow-processed');
        //resize_nav_overflow();
        
        if($('.navbar-collapse').hasClass('in')){
          $('#navbar-collapse').collapse('toggle');
        }
        $('#navbar-collapse-secondary').collapse('show');
      },
      unmatch: function(){
        console.log('running unmatch');
        $('li.split').unbind('hover');
        $(window).off('resize.bs.dropdown');
        resize_nav_overflow();
        $('#navbar-collapse-secondary').collapse('hide');
      }
    };
    
    //run js when navbar collapses
    enquire.register("screen and (min-width:"+breakpoints.grid_float_breakpoint+")", {
      setup : function() {},
      match : function() {
        nav_collapse_functions.match();
      },
      unmatch : function() {
        nav_collapse_functions.unmatch();
      }
    });
  
    
    
    /*
    var $container = $('.radix-layouts-full-width .panel-panel-inner .full_width_left .row');
    $container.imagesLoaded( function () {  
      $container.masonry({
        columnWidth: '.panel-pane.pane-fieldable-panels-pane',
        itemSelector: '.panel-pane.pane-fieldable-panels-pane',    
        //fitWidth: true
      });
    });
    */
    
    $homepage_modal_link = '<a data-target="#homepageDiagram" data-toggle="modal" href="#" class="modal-link"></a>';
    $homepage_modal_body = $('.full_width_right .fieldable-panels-pane-meda-block-large .pane-fieldable-panels-pane-field-mb-media .content').html();
    $modal_content = '<div class="modal fade" id="homepageDiagram" tabindex="-1" role="dialog" aria-labelledby="homepageDiagramLabel"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><h4 class="modal-title" id="homepageDiagramLabel">Diagram</h4></div><div class="modal-body">'+$homepage_modal_body+'</div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">Close</button></div></div></div></div>';
    //$('.full_width_right .fieldable-panels-pane-meda-block-large .pane-fieldable-panels-pane-field-mb-media .content').append($homepage_modal_link);
    $('.full_width_right .fieldable-panels-pane-meda-block-large .pane-fieldable-panels-pane-field-mb-media .content img').wrap($homepage_modal_link).parent().append('<i class="fa fa-search-plus" aria-hidden="true"></i>');
    $('body').append($modal_content);
    $('.full_width_right .panel-pane:not(":first-child")').clone().addClass('visible-xs visible-ms visible-sm').appendTo('.radix-layouts-footer .panel-panel-inner');
    $('.full_width_right .panel-pane:not(":first-child")').addClass('visible-md visible-lg');

  
    /*
$grid.isotope({
  itemSelector: '.element-item',
  layoutMode: 'fitRows',
})
*/

/*
var $container = $('.radix-layouts-full-width .panel-panel-inner .full_width_left .row');
$container.imagesLoaded( function () {  
  //jQuery('.pane-bundle-media-block').each(function(index,value){jQuery(this).append("<div class='weight'>"+index+"</div>");});
  $container.isotope({
    itemSelector: '.panel-pane.pane-fieldable-panels-pane',
    layoutMode: 'packery',
    packery: {
    gutter: 0
    },
    //sortBy: 'weight',
    //getSortData: {
    //  weight: function( itemElem ) { // function
    //    var weight = jQuery( itemElem ).find('.weight').text();
    //    console.log(weight);
    //    return parseFloat( weight.replace( /[\(\)]/g, '') );
    //  }
    //}
  });
});
  */
  
 
    $headroom_options = {
      //"offset": $('#header').outerHeight(),
      "offset": 5,
      classes : {
          // when element is initialised
          initial : "headroom--initialised",
          // when scrolling up
          pinned : "headroom--pinned",
          // when scrolling down
          unpinned : "headroom--unpinned",
          // when above offset
          top : "headroom--top",
          // when below offset
          notTop : "headroom--not-top",
          // when at bottom of scoll area
          bottom : "headroom--bottom",
          // when not at bottom of scroll area
          notBottom : "headroom--not-bottom"
      },      
      //"tolerance": 5,
      onTop : function() {
        console.log('on top');
        //if($('#main-wrapper').css('paddingTop') != '0px'){
          $('#main-wrapper').css('paddingTop', $('#header').outerHeight());
        //}
        
        //$('.radix-layouts-sidebar').removeAttr('style');
      },
      onNotTop : function() {

        //$('#main-wrapper').addClass('no-transition');
        
        //console.log('on not top');
        //console.log($('#header').outerHeight() > ($('#page').height() - $(window).height()));
        //console.log($('#header').outerHeight());
        //console.log($('#page').height() - $(window).height());
        if($('#header').outerHeight() <= ($('#page').height() - $(window).height())-20){
          $('#header').removeClass('absolute');
          console.log('on not top setting padding to 0');
          $('#main-wrapper').css('paddingTop', 0);
        }else{
          console.log('header bigger than space aviable');
          $('#header').addClass('absolute');
        } 

      },
      onBottom: function(){
        console.log('on bottom');
        //$('.radix-layouts-sidebar').removeAttr('style');
        
      },
      onNotBottom: function(){
        console.log('not bottom');
        //$('#main-wrapper').removeClass('no-transition');
      },
      onPin : function() {
        console.log('on pin');
        //$('.sidebar-panel-inside.affix').css({marginTop: $('#header').outerHeight() });
        
        //(jQuery('.sidebar-panel-inside').offset().top + jQuery('.sidebar-panel-inside').outerHeight())
        
        //if(!$('.sidebar-panel-inside').hasClass('affix-bottom')){ $('.radix-layouts-sidebar').css({paddingTop: $('#header').outerHeight() }); }
      },
      onUnpin : function() {
        console.log('on unpin');
        //$('#main-wrapper').css('paddingTop', 0);
        //$('.sidebar-panel-inside.affix').removeAttr('style');
        //$('.radix-layouts-sidebar').removeAttr('style');
      },    
    }
      
    var fixed_header_height = function() {
      console.log('running fixed header height');
      
      if(!$('.navbar-collapse').hasClass('clicked')){
        if(!$('#header').hasClass('headroom--unpinned')){
          $('#main-wrapper').css('paddingTop', $('#header').outerHeight());
        }else{
          $('#main-wrapper').css('paddingTop', 0);
        }
      }
      //if($('#main-wrapper').css('paddingTop') != '0px'){
        //$('#main-wrapper').css('paddingTop', $('#header').outerHeight());
      //}
      //panel_media_header_fixed();
      //console.log('running headroom');
      //console.log($headroom_options);
      //$('#header.fixed').headroom('destroy').removeData('headroom').headroom( $headroom_options );
      
    };
    var bottom_footer = function(){
      console.log('running bottom footer');
      footer_height = $('#footer').outerHeight();
      $('.footer_spacer').css('height',footer_height);
      $('#page').css('marginBottom',-footer_height);
    };



var panel_media_header_fixed = function(){
  //console.log('running panel media header fixed');
  //var fromTop = $(window).scrollTop();
  //header_height = ($('#header').hasClass('headroom--unpinned')) ?  0 : $('#header').outerHeight();
  //if(fromTop >= $('#header').outerHeight()){
    //$("body.panel-layout-gdt_pond #main .row-header .radix-layouts-header").css('transform','translateY(0px)');
    //$("body.panel-layout-gdt_pond #main .row-header .radix-layouts-header").css('paddingTop','0');
  //}else{
    //$("body.panel-layout-gdt_pond #main .row-header .radix-layouts-header").css('transform', 'translateY('+(header_height - fromTop) + 'px)');
    //$("body.panel-layout-gdt_pond #main .row-header .radix-layouts-header").css('paddingTop', (header_height - fromTop) + 'px)');
  //}
}



    //$('#header').clone(true).addClass('fixed').attr('id','header-clone').insertAfter('#page');
    //$('.header.fixed').headroom( $headroom_options );
    $('#header').addClass('fixed').headroom( $headroom_options );
    //$('#header').addClass('fixed');
    
    //$(window).load(function(){
      console.log('triggering resizes');
      $('#header').resize(fixed_header_height);
      $('#footer').resize(bottom_footer);
    //});

    //$('#header').removeResize(myFunc);
    
    
    
    /*
$triggered_times = 0;

$(window).on('scroll', function() {
var y_scroll_pos = window.pageYOffset;
var scroll_pos_test = 150;   // set to whatever you want it to be

if(y_scroll_pos > scroll_pos_test && $triggered_times == 0 ) {
//do your stuff over here
$triggered_times = 1;   // to make sure the above action triggers only once
}
});    
    */
    
    
if($('#flexslider-1').length){
  $(window).scroll(function(){
    if(window.pageYOffset >= $('#flexslider-1').outerHeight()){
      if(!$('#flexslider-1').hasClass('flexslider-pause')){
        console.log('flexslider no longer in view');
        $('#flexslider-1').addClass('flexslider-pause').flexslider('pause');
      }
    }else{
      if($('#flexslider-1').hasClass('flexslider-pause')){
        console.log('flexslider back in view');
        $('#flexslider-1').removeClass('flexslider-pause').flexslider('play');
      }
    }
  });
}
    

/*
$(window).scroll(function(){
  //jQuery('.flexslider').flexslider('pause')
  //panel_media_header_fixed();
  

  
  
  //if($(window).scrollTop() >= $('#header').outerHeight()){
    //$('#header-clone').css('opacity',1);
    
    //if(!$('#header').hasClass('fixed')){
    //  $('#header').addClass('fixed');
    //  $('#main-wrapper').css('paddingTop', $('#header').outerHeight());    
    //}
    
  //}else{
    //$('#header-clone').css('opacity',0);
    
    //if($('#header').hasClass('fixed')){
    //  $('#header').removeClass('fixed');
    //  $('#main-wrapper').css('paddingTop', 0);
    //}
    
 // }
  
  
});
*/

    if($('.radix-layouts-header').length){
      $('.radix-layouts-header .flexslider').imagesLoaded( function() {
        $('.radix-layouts-header .panel-panel-inner').addClass('done');
      });
    }
    

    //paragraph-row
    if( !$('.paragraph-row-tab').hasClass('accordion-show-all') ){
      $('.paragraph-row-tab').tabCollapse({
        tabsClass: 'hidden-sm hidden-ms hidden-xs',
        accordionClass: 'visible-ms visible-sm visible-xs accordion-on'
      });
    }else{
      $('.paragraph-row-tab').tabCollapse({
        tabsClass: 'hidden-lg hidden-md hidden-sm hidden-ms hidden-xs',
        accordionClass: 'visible-lg visible-md visible-ms visible-sm visible-xs accordion-on'
      });  
    }
    $(document).on('shown.bs.collapse','.accordion-on', function (e) {
      // Validate this panel belongs to this accordian, and not an embedded one
      var actualAccordianId = $('a[href="#' + $(e.target).attr('id') + '"]').data('parent')
      var targetAccordianId = '#' + $(this).attr('id');
      if (actualAccordianId !== targetAccordianId) return;
    
      var clickedHeader = $(this).find('.panel > .collapse.in').closest('.panel').find('.panel-heading');
      var offset = clickedHeader.offset();
      var top = $(window).scrollTop();
      if(offset) {
        var topOfHeader = offset.top - $('#header').outerHeight() - 40;
        if(topOfHeader < top) {
          $('html,body').animate({ scrollTop: topOfHeader}, 100, 'swing');
        }
      }
    });

    
	});
  //end of dom ready

  var bootstrap_OffsetDropdown = {
    load: function(){
      var $dropdowns = $('.dropdown-menu');
      $dropdowns.each(function(){
        var dropdown = $(this);
        dropdown.removeAttr('style');
        dropdown.addClass('show invisible');
        var rightOffset = dropdown.offset().left,
        containerWidth = $('.navbar').innerWidth(),
        containerOffset = $('.navbar').offset().left,
        neededLeftOffset = (containerWidth+containerOffset) - rightOffset - dropdown.width();
        dropdown.removeClass('show invisible');
        if(neededLeftOffset < 0){
          dropdown.css({ left: neededLeftOffset - 3 }).addClass('border-top-left');
        }else{
          dropdown.css({ left: 0 }).removeClass('border-top-left');
        }
      });
    }
  }
  
})(jQuery);
(function($){
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;
      return function debounced () {
          var obj = this, args = arguments;
          function delayed () { if (!execAsap) func.apply(obj, args); timeout = null; };
          if (timeout) clearTimeout(timeout);
          else if (execAsap) func.apply(obj, args);
          timeout = setTimeout(delayed, threshold || 100);
      };
  }
  // smartresize
  $.fn['smartresize'] = function(fn,namespace){
		if(!namespace){namespace='smart';}
		return fn ? this.bind('resize.'+namespace, debounce(fn)) : this.trigger('smartresize');
	};  
})(jQuery);